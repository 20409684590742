




































































import useAlert from '@/use/alert'
import useUser from '@/use/user'
import { computed, defineComponent, nextTick, onMounted, reactive, ref, watch } from '@vue/composition-api'
import { AxiosInstance } from 'axios'
import { DataOptions } from 'vuetify'

export default defineComponent({
  components: {
    MCompanyFilter: () => import('@/components/molecules/m-company-filter.vue')
  },

  setup(_, { root }) {
    const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

    const alerts = ref<any[]>([])

    const {
      getAlertType,
      getAlertTypeIcon,
      getAlertStatus,
      getAlertEndDate,
      getAlertEndMileage,
      getAlertVehicle,
      getAlertCompany,
      getAlertTimeLeft,
    } = useAlert()

    const { hasAccessTo, hasManyCompanies } = useUser({ root })

    const state = reactive({
      loading: false,
      headers: [
        { value: 'type', text: 'Typ', sortable: false, width: 1 },
        { value: 'date', text: 'Data wyświetlenia alertu', sortable: false, width: 1 },
        { value: 'mileage', text: 'Przebieg wyświetlenia alertu', sortable: false, width: 1 },
        { value: 'endDate', text: 'Termin wykonania działania', sortable: false, width: 1 },
        { value: 'endMileage', text: 'Przebieg wykonania działania', sortable: false, width: 1 },
        { value: 'vehicle', text: 'Pojazd', sortable: false, width: 3 },
        { value: 'company', text: 'Firma', sortable: false, width: 3 }
      ],
      items: computed(() => alerts.value.map(alert => ({ ...alert }))),
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
      },
      total: 0
    })

    const model = reactive({
      company: null as any
    })

    const fetch = () => {
      const { page, itemsPerPage } = state.options as DataOptions

      state.loading = true

      const filter = {
        company: model.company ? model.company.id : undefined
      }

      axiosInstance
        .get('alert', { params: { page, itemsPerPage, future: true, status: ['to_do', 'in_progress', 'done'], filter } })
        .then(({ data }) => {
          alerts.value = data.alerts
          state.total = data.total
        })
        .catch(() => {
          alerts.value = []
          state.total = 0
        })
        .finally(() => state.loading = false)
    }

    onMounted(fetch)
    watch(() => state.options, fetch, { deep: true })
    watch(model, () => (state.options.page = 1), { deep: true })
    watch(model, fetch, { deep: true })
    watch(model, () => nextTick(fetch), { deep: true })
    watch(model, () => setTimeout(fetch, 100), { deep: true })


    const onRowClick = (event: MouseEvent, data: any) => {
      root.$router.push({ name: 'panel.alert.view', params: { id: data.item.id } })
    }

    return {
      hasAccessTo,
      hasManyCompanies,

      state,
      model,

      getAlertType,
      getAlertTypeIcon,
      getAlertStatus,
      getAlertEndDate,
      getAlertEndMileage,
      getAlertVehicle,
      getAlertCompany,
      getAlertTimeLeft,

      onRowClick,
    }
  }
})
