var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-alert-list-future"},[(_vm.hasAccessTo('employee') || _vm.hasManyCompanies())?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('m-company-filter',{attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.model.company),callback:function ($$v) {_vm.$set(_vm.model, "company", $$v)},expression:"model.company"}})],1)],1)],1):_vm._e(),_c('v-divider'),_c('v-data-table',{attrs:{"loading":_vm.state.loading,"headers":_vm.state.headers,"items":_vm.state.items,"options":_vm.state.options,"server-items-length":_vm.state.total,"footer-props":{ itemsPerPageOptions: [10, 25, 50, 100] },"mobile-breakpoint":1904},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertType(item.type))?_c('span',{staticClass:"text-no-wrap"},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.getAlertTypeIcon(item.type)))]),_vm._v(" "+_vm._s(_vm.getAlertType(item.type)))],1):_c('span',{staticClass:"text-no-wrap"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")]),_vm._v(" Inny")],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.date)?_c('v-list-item',{staticClass:"text-no-wrap pa-0",attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm._f("moment")(item.date,'from', 'now')))]),_c('v-list-item-subtitle',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm._f("moment")(item.date,'DD.MM.YYYY')))])],1)],1):_c('v-divider')]}},{key:"item.mileage",fn:function(ref){
var item = ref.item;
return [(item.mileage)?_c('span',{staticClass:"text-no-wrap grey--text"},[_vm._v(_vm._s(item.mileage.toLocaleString())+" km")]):_c('v-divider')]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertEndDate(item))?_c('span',{staticClass:"text-no-wrap"},[_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v(_vm._s(_vm._f("moment")(_vm.getAlertEndDate(item),'DD.MM.YYYY')))])],1):_c('v-divider')]}},{key:"item.endMileage",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertEndMileage(item))?_c('span',{staticClass:"text-no-wrap"},[_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v(_vm._s(_vm.getAlertEndMileage(item).toLocaleString())+" km")])],1):_c('v-divider')]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertStatus(item.status))?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.getAlertStatus(item.status)))]):_c('v-divider')]}},{key:"item.vehicle",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertVehicle(item))?_c('v-list-item',{staticClass:"text-no-wrap pa-0",attrs:{"dense":""}},[_c('v-list-item-action',{staticClass:"mr-2 mt-3 mb-3"},[_c('v-btn',{attrs:{"icon":"","small":"","to":{ name: 'panel.vehicle.view', params: { id: _vm.getAlertVehicle(item).id } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-car")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('router-link',{attrs:{"to":{ name: 'panel.vehicle.view', params: { id: _vm.getAlertVehicle(item).id } }}},[_vm._v(_vm._s(_vm.getAlertVehicle(item).registration))])],1),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.getAlertVehicle(item).name))])],1)],1):_c('v-divider')]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertCompany(item))?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.getAlertCompany(item).name))]):_c('v-divider')]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }